<template>
  <div class="page-box page-news  bg-white">
    <ban-view :banStyle="banStyle"></ban-view>
    <div class="bxb-con bxb-p50">
      <news-list :newsList="newsList"></news-list>
    </div>
  </div>
</template>

<script>
import BanView from "components/content/banView/BanView";
import NewsList from "components/content/newsList/NewsList";
export default {
  name: "Home",
  components: {
    BanView,
    NewsList
  },
  data() {
    return {
      banStyle: {
        height: "300px",
        backgroundImage: 'url("https://ylxd.xcongzb.com/img/ban-news.jpg")'
      },
      newsList: [
        {
          id: 0,
          title: "同心协力  合作共赢",
          text:
            "2020年12月31日百色市远兰小额贷款有限责任公司与中国人寿财产保险有限公司正式签约“提放保”业务合作，国寿财中心领导与我公司融资部总监郭总共同签署了该合作协议书，同时标志着我公司与中国人寿财产合作正式进入实施阶段",
          img: "https://ylxd.xcongzb.com/img/news01.jpg"
        },
        {
          id: 1,
          title: "金钟徒步，园林野炊，我们一起走进温柔的秋天",
          text:
            "落叶知秋，微风袭来,秋天褪去了夏日的燥热，带着一丝凉意,天朗气清的今天,我们徒步金钟水库，到山庄园林野炊",
          img: "https://ylxd.xcongzb.com/img/news02.jpg"
        },
        {
          id: 2,
          title: "感谢有你，携手同行—深圳分公司员工生日会",
          text:
            "小时候，生日是一家人的欢乐；有父母、有蛋糕、有蜡烛；上学时，生日是同学们的联欢；有同窗情谊、有举杯欢畅；工作后，生日是同事们的欢聚；有筑梦未来、有携手共进。",
          img: "https://ylxd.xcongzb.com/img/news03.jpg"
        },
        {
          id: 3,
          title: "热烈祝贺郑州小葱--乔迁新居！！！",
          text: "新家新气象！笑语声声，共庆乔迁喜！！！",
          img: "https://ylxd.xcongzb.com/img/news04.jpg"
        },
        {
          id: 4,
          title: "郑州小葱成立三周年二季度团建----重渡沟三日游",
          text:
            "3年见证成长，携手同登彼岸，时钟的齿轮转过三载春秋，回首一路走过的印记，内心漫溢感恩。公司的优秀员工、核心骨干很多都是从公司成立之初就跟公司一起并肩作战、共同成长，公司发展的每一步都离不开幕后这么一群辛苦工作的兄弟姐妹",
          img: "https://ylxd.xcongzb.com/img/news05.jpg"
        },
        {
          id: 5,
          title: "首届篮球友谊赛（深圳VS中山）",
          text:
            "青春无畏，逐梦扬威。或许你已经忘了学生时代的最好成绩，但是你却不会忘记篮球带给你的快乐，因为篮球挥洒了你的热血青春",
          img: "https://ylxd.xcongzb.com/img/news06.jpg"
        },
        {
          id: 6,
          title: "新的环境 新的开始",
          text:
            "成功就像你种下的一颗种子，你唯有心心念念和不离不弃的浇灌和守护它，才能有盛开绽放的一天。",
          img: "https://ylxd.xcongzb.com/img/news07.jpg"
        },
        {
          id: 7,
          title: "从穷人到富人的全过程",
          text:
            "一个人用100元买了50双拖鞋，拿到地摊上每双卖3元，一共得到了150元。另一个人很穷，每个月领取100元生活补贴，全部用来买大米和油盐。同样是100元，前一个100元通过经营增值了，成为资本。后一个100元在价值上没有任何改变，只不过是一笔生活费用。",
          img: "https://ylxd.xcongzb.com/img/news08.jpg"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.content {
  font-size: 18px;
  line-height: 2.4;
  img {
    margin: 20px auto;
  }
}
</style>
