<template>
  <div class="news-list flex flex-wrap">
    <div
      class="item flex flex-wrap flex-column"
      v-for="item in newsList"
      :key="item.id"
    >
      <img :src="item.img" :alt="item.title" />
      <h6>{{ item.title }}</h6>
      <p>
        {{ item.text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsList",
  props: {
    newsList: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.news-list {
  .item {
    width: 33.33%;
    padding: 20px 20px;
    img {
      width: 100%;
      border-radius: 10px;
    }
    h6 {
      margin: 15px 0 10px;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 18px;
      font-weight: bold;
      color: #222;
    }
    p {
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 15px;
      color: #777;
      line-height: 1.6;
    }
  }
}
</style>
